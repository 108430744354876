import editor from 'vue2-ace-editor'

export default {
  components: {editor},
  methods: {
    editorInit () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/json')
      require('brace/theme/chrome')
    },
  },
}
