<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
              >組織資料設定</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'TemplateList',
              }"
              >通知樣板管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{ (isEditing) ? '編輯' : '新增' }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-form class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card :title="(isEditing) ? `${organizationName}編輯通知樣板` : `${organizationName}新增通知樣板`">
            <b-card-body>
              <validation-error-alert v-if="validationErrors" :errors="validationErrors"></validation-error-alert>
              <b-form-group label="Key">
                <b-form-input type="text" v-model="template.key" required readonly></b-form-input>
              </b-form-group>

              <b-form-group label="Notification Type (選擇 LINE 時可以預覽 Flex Card)">
                <b-form-select v-model="template.notification_type" disabled>
                  <b-form-select-option :value="null">請選擇</b-form-select-option>
                  <b-form-select-option value="line">LINE</b-form-select-option>
                  <b-form-select-option value="email">EMAIL</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Type">
                <b-form-select v-model="template.type" required disabled>
                  <b-form-select-option :value="null">請選擇</b-form-select-option>
                  <b-form-select-option value="json">JSON</b-form-select-option>
                  <b-form-select-option value="html">HTML</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-row>
                <b-form-group v-if="template.notification_type === 'line'" label="Live Preview" class="col-12 col-sm-6 col-lg-3">
                  <div class="p-2" style="background-color: #849ebf;">
                    <div id="preview" v-html="lineFlexCard"></div>
                  </div>
                </b-form-group>

                <b-form-group label="Value" class="col-12 col-sm-6 col-lg-9">
                  <editor
                    v-if="template.type === 'json'"
                    v-model="template.value"
                    width="100%"
                    height="200px"
                    @init="editorInit"
                    lang="json"
                    theme="chrome"
                  >
                  </editor>
                  <b-form-textarea v-else placeholder="請輸入參數內容" v-model="template.value" rows="6" max-rows="25"></b-form-textarea>
                </b-form-group>
              </b-row>

              <b-row class="my-3">
                <b-col>
                  <b-button class="mr-3" @click="handleCancel" variant="outline-danger">返回</b-button>
                  <b-button v-if="!isShow" @click="submit" variant="success">儲存</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import templateApi from "../../../apis/template";
import flex2html from '/libs/flex2html'
import jsonEditorMixin from "@/mixins/jsonEditor";

export default {
  mixins: [jsonEditorMixin],
  data () {
    return {
      showLoading: true,
      isEditing: true,
      validationErrors: null,
      template: {
        key: null,
        value: null,
        type: null,
        notification_type: null,
      },
      organizationName: this.$store.state.general.organization.name,
    }
  },
  computed: {
    ...mapState('general', {
      organization: state => state.organization,
    }),
    isShow() {
      return this.$route.name == 'TemplateShow'
    },
    isEdit() {
      return this.$route.name == 'TemplateEdit'
    },
    lineFlexCard () {
      let res = ''
      try {
        let obj = JSON.parse(this.template.value)
        res = flex2html(obj)
      } catch (e) {
        // ignore
        console.error(e)
      }
      return res
    }
  },
  mounted() {
    if (! this.$route.params.template_id) {
      this.clearTemplate()
      this.isEditing = false
      this.showLoading = false
    } else {
      this.fetchTemplate()
      this.isEditing = true
    }
  },
  methods: {
    async fetchTemplate() {
      const response = await templateApi.get({
        org_code: this.organization.id,
        merchant_id: this.$route.params.merchant_id,
        template_id: this.$route.params.template_id,
      })
      this.template = response.data.data
      this.showLoading = false
    },
    clearTemplate () {
      this.template = {
        key: null,
        value: null,
        type: null,
      }
    },
    async submit () {
      try {
        if (this.isEditing) {
          let response = await templateApi.update({
            org_code: this.organization.id,
            merchant_id: this.$route.params.merchant_id,
            template: this.template,
          })
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: '更新成功',
              type: 'success'
            })
            this.template = response.data.data
          }
        } else {
          let response = await templateApi.store(this.template)

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: '新增成功',
              type: 'success'
            })
            this.$router.push({name: 'TemplateList'})
          }
        }
      } catch (error) {
        console.log(error)

        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message
        }
      }
    },
    handleCancel () {
      this.$router.push({name: 'TemplateList'})
    }
  }
}
</script>

<style>
@import "../../../../libs/flex2html.css";
</style>
