import https from "./https";

const template = {
  list({page, org_code, merchant_id}) {
    let params = {page}
    return https.get(`/admin/organizations/${org_code}/merchants/${merchant_id}/templates`, {params});
  },

  get({org_code, merchant_id, template_id}) {
    return https.get(`/admin/organizations/${org_code}/merchants/${merchant_id}/templates/${template_id}`);
  },

  update({org_code, merchant_id, template}) {
    return https.put(`/admin/organizations/${org_code}/merchants/${merchant_id}/templates/${template.id}`, template);
  },

  store() {
    // TODO
  }
};

export default template;
